<template>
  <vx-card :loading="loadingInitValues">
    <vx-form
      v-if="isLocalesExists"
      v-slot="{ can, loading }"
      :resource="formResource"
      @submit="handleSubmit"
    >
      <vx-input
        v-model="title"
        :rules="rules.title"
        name="title"
        label="Title"
      />

      <vx-input
        v-model="formValue.slug"
        :rules="rules.slug"
        name="slug"
        label="Slug"
      />

      <vx-input
        v-model="type"
        :rules="rules.type"
        debounce
        name="type"
        label="Type"
      />

      <b-tabs v-model="tab">
        <b-tab v-for="(label, code) in localState.locales" :key="code" lazy>
          <template #title>
            {{ label }}
          </template>

          <vx-textarea
            v-model="formValue.translations[code]"
            :rules="rules.translations"
            immediate
            name="text"
          />
        </b-tab>
      </b-tabs>

      <vx-button
        :loading="loading"
        :can="can"
        variant="primary"
        type="submit"
      >
        Save
      </vx-button>
    </vx-form>
    <span v-if="!isLocalesExists && !loadingInitValues">
      There are no available languages for translations.
    </span>
  </vx-card>
</template>

<script>
import languageCodes from '@/services/utils/locales.json'
import { BTabs, BTab } from 'bootstrap-vue'
import VxCard from '@/components/VxCard'
import { VxForm, VxInput, VxTextarea } from '@/components/form'
import { VxButton } from '@/components/buttons'
import router, { getRouteIdParam, path } from '@/router'
import { computed, onBeforeMount, reactive, set } from '@vue/composition-api'
import { contents, passDataToResource, translations, useResource } from '@/services/resources'
import { setValuesToForm } from '@/services/form'
import { isEmpty } from 'lodash'
import slugify from '@sindresorhus/slugify'

const defaultTab = 0

export default {
  name: 'ContentEditor',
  components: {
    VxCard,
    VxForm,
    VxInput,
    VxTextarea,
    VxButton,
    BTabs,
    BTab
  },
  props: {
    dataResolverResponse: {
      type: Object,
      default: null
    }
  },
  setup ({ dataResolverResponse }) {
    const isEdit = !!dataResolverResponse

    const { can: canGetLocales, loading, callRequest } = useResource(translations.getAvailableLocales)

    const isLocalesExists = computed(() => !isEmpty(localState.locales))
    const isTranslationRequired = computed(() => localState.activeTab === defaultTab)
    const tab = computed(({
      get: () => localState.activeTab,
      set: (val) => {
        localState.activeTab = val
        rules.translations.required = isTranslationRequired.value
      }
    }))

    const type = computed({
      get: () => formValue.type,
      set: (value) => {
        formValue.type = slugify(value, { separator: '_' })
      }
    })

    const title = computed({
      get: () => formValue.title,
      set: (value) => {
        formValue.title = value
        formValue.slug = slugify(value)
      }
    })

    const getAvailableLocales = async () => {
      if (!canGetLocales) return

      const [err, res] = await callRequest({ params: null })
      if (err) return

      res.data.available_locales.forEach((code) => {
        set(localState.locales, code, languageCodes[code])
      })
    }

    const frontToBackMapper = () => {
      return {
        ...formValue,
        translations: Object.keys(formValue.translations)
          .map((lang) => ({ lang, text: formValue.translations[lang] }))
          .filter(item => item.text)
      }
    }

    const backToFrontMapper = (data) => {
      return {
        ...data,
        translations: Object.fromEntries(data.translations.map(({ lang, text }) => [lang, text]))
      }
    }

    const formResource = isEdit
      ? passDataToResource(contents.update, {
        frontToBackMapper,
        requestParams: { urlParams: { id: getRouteIdParam() } }
      })
      : passDataToResource(contents.create, {
        frontToBackMapper
      })

    const localState = reactive({
      activeTab: defaultTab,
      locales: {}
    })

    const formValue = reactive({
      slug: '',
      type: '',
      title: '',
      translations: {}
    })

    const textFieldDefaultRules = {
      required: true,
      min: 3,
      max: 200
    }

    const rules = {
      title: textFieldDefaultRules,
      slug: textFieldDefaultRules,
      type: textFieldDefaultRules,
      translations: {
        max: 2000,
        required: isTranslationRequired.value
      }
    }

    const switchToDefaultTab = () => {
      tab.value = defaultTab
    }

    const handleSubmit = async ([err]) => {
      if (err || isEdit) {
        switchToDefaultTab()
        return
      }

      await router.push(path.contents)
    }

    onBeforeMount(async () => {
      await getAvailableLocales()
      if (isEdit) {
        setValuesToForm(backToFrontMapper(dataResolverResponse), formValue)
      }
    })

    return {
      formResource,
      formValue,
      localState,
      rules,

      title,
      type,
      tab,

      loadingInitValues: loading,
      isLocalesExists,
      handleSubmit
    }
  }
}
</script>
